body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.CalendarDay,.CalendarDay:active{
  color: #000000;
  background-color: #90ee90;
  border: 1px solid #90ee90;
}
.CalendarDay:hover {
  background-color: #7bff7b;
  border: 1px solid #7bff7b;
}
.CalendarDay__blocked_out_of_range,.CalendarDay__blocked_out_of_range:active,.CalendarDay__blocked_minimum_nights,
.CalendarDay__blocked_minimum_nights:active {
  color: #000000;
  background-color: #969696;
  border: 1px solid #969696;
}
.CalendarDay__blocked_out_of_range:hover,.CalendarDay__blocked_minimum_nights:hover{
  background-color: #adadad;
  border: 1px solid #adadad;
}
.CalendarDay__selected_span,.CalendarDay__selected_span:active{
  color: #ffffff;
  background-color: #33dacd;
  border: 1px solid #33dacd;
} 
.CalendarDay__selected_span:hover {
  background-color: #34eede;
  border: 1px solid #34eede;
}
.CalendarDay__selected,.CalendarDay__selected:active {
  color: #ffffff ;
  background-color: #00a699;
  border: 1px solid #00a699;
}
.CalendarDay__selected:hover{
  background-color: #00cebd;
  border: 1px solid #00cebd;
}

.carousel-control-prev,.carousel-control-next {
  height: 65vh;
}

